import { AdditiveBlending, BufferGeometry, Float32BufferAttribute, FloatType, NearestFilter, Points, PointsMaterial, Scene, Texture } from "three";

export function addRainParticles(particlesCount: number, minRange: number, maxRange: number, scene: Scene) {
  const pointMaterial = new PointsMaterial({
    size: 0.5,
    color: 0x4794b5,
    vertexColors: false,
    map: getRainTexture(),
    blending: AdditiveBlending,
    transparent: true,
    opacity: 0.4,
    fog: true,
    depthWrite: false
  });

  addParticles(particlesCount, minRange, maxRange, pointMaterial, scene);
}

export function addSnowParticles(particlesCount: number, minRange: number, maxRange: number, scene: Scene) {
  const pointMaterial = new PointsMaterial({
    size: 1.5,
    color: 0xffffff,
    vertexColors: false,
    map: getSnowTexture(),
    blending: AdditiveBlending,
    transparent: true,
    opacity: 0.7,
    fog: true,
    depthWrite: false
  });

  addParticles(particlesCount, minRange, maxRange, pointMaterial, scene);
}

export function removeParticles(scene: Scene) {
  scene.children.forEach(async (child) => {
    if (child instanceof Points) {
      scene.remove(child);
      if (child.geometry) child.geometry.dispose();
      if (child.material) child.material.dispose();
    }
  });
}

function addParticles(particlesCount: number, minRange: number, maxRange: number, pointsMaterial: PointsMaterial, scene: Scene) {
  const geometry = new BufferGeometry();
  const vertices = [];

  for (let i = 0; i < particlesCount; i++) {
    const x = Math.random() * maxRange - minRange;
    const y = Math.random() * maxRange - minRange;
    const z = Math.random() * maxRange - minRange;

    vertices.push(x, y, z);
  }

  geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));

  const particles = new Points(geometry, pointsMaterial);

  scene.add(particles);
}

function drawRadialGradation(ctx: CanvasRenderingContext2D, canvasRadius: number, canvasW: number, canvasH: number): void {
  ctx.save();
  const gradient = ctx.createRadialGradient(canvasRadius, canvasRadius, 0, canvasRadius, canvasRadius, canvasRadius);
  gradient.addColorStop(0, 'rgba(255,255,255,1.0)');
  gradient.addColorStop(0.5, 'rgba(255,255,255,0.5)');
  gradient.addColorStop(1, 'rgba(255,255,255,0)');
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, canvasW, canvasH);
  ctx.restore();
}

function drawRaindropGradient(ctx: CanvasRenderingContext2D, canvasRadius: number, canvasW: number, canvasH: number): void {
  ctx.save();
  const gradient = ctx.createRadialGradient(canvasRadius, canvasRadius, 0, canvasRadius, canvasRadius, canvasRadius);
  gradient.addColorStop(0, 'rgba(255,255,255,1.0)');
  gradient.addColorStop(0.5, 'rgba(255,255,255,0.5)');
  gradient.addColorStop(1, 'rgba(255,255,255,0)');
  ctx.fillStyle = gradient;
  ctx.beginPath();
  ctx.ellipse(canvasRadius, canvasRadius, canvasRadius / 4, canvasRadius, Math.PI, 0, 2 * Math.PI);
  ctx.fill();
  ctx.restore();
}

function getSnowTexture() {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const diameter = 64.0;
  canvas.width = diameter;
  canvas.height = diameter;
  const canvasRadius = diameter / 2;

  drawRadialGradation(ctx as CanvasRenderingContext2D, canvasRadius, canvas.width, canvas.height);

  const texture = new Texture(canvas);
  texture.minFilter = NearestFilter;
  texture.type = FloatType;
  texture.needsUpdate = true;
  return texture;
}

function getRainTexture() {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const diameter = 64.0;
  canvas.width = diameter;
  canvas.height = diameter;
  const canvasRadius = diameter / 2;

  drawRaindropGradient(ctx as CanvasRenderingContext2D, canvasRadius, canvas.width, canvas.height);

  const texture = new Texture(canvas);
  texture.minFilter = NearestFilter;
  texture.type = FloatType;
  texture.needsUpdate = true;
  return texture;
}