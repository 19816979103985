
import { IReferal } from '../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Typography from '../../common/typography';

import styles from './FriendItem.module.scss';

import { ReactComponent as TreeScoreIcon } from '../../../assets/friends/TreeScoreIcon.svg';
import { ReactComponent as BellIcon } from '../../../assets/friends/BellIcon.svg';
import { useCallback, useMemo, useState } from 'react';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { remindReferral } from '../../../api/auth';
import { handleErrors } from '../../../core/helpers';
import ButtonLoader from '../../common/loaders/buttonLoader';
import IconButton from '../../common/iconButton';
import { fetchReferrals } from '../../../store/reducers/accountSlice';
import { getDefaultAvatarSrc } from '../../../utils/utils';

interface FriendItemProps {
  friend: IReferal,
}

const FriendItem = ({ friend }: FriendItemProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const assets = useAppSelector(state => state.settings.settings.assets);
  const scoreAsset = useMemo(() => {
    return assets.find((item) => item.type === 'Score');
  }, [assets]);
  const formattedAsset = useFormattedAsset(scoreAsset?.assetId || null, friend.score);

  const dispatch = useAppDispatch();

  const rightIcon = useMemo(() => {
    if (isLoading) {
      return <ButtonLoader />;
    }
    if (friend.allowRemind) {
      return <BellIcon />;
    }
    return <BellIcon style={{ stroke: 'var(--basic--secondary)' }} />;
  }, [isLoading, friend.allowRemind]);

  const isDisabled = useMemo(() => {
    if (isLoading || !friend.allowRemind) {
      return true;
    }
    return false;
  }, [isLoading, friend.allowRemind]);

  const handleRemind = useCallback(async () => {
    try {
      setIsLoading(true);
      await remindReferral(friend.id);
      await dispatch(fetchReferrals());
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [friend.id, dispatch]);

  const avatarUrl = useMemo(() => {
    if (!friend.photoUrl) {
      return getDefaultAvatarSrc(friend.id);
    }
    return friend.photoUrl;
  }, [friend]);

  return (
    <div className={styles.friend_item__wrapper}>
      <div className={styles.friend_item}>
        <div className={styles.friend_info__wrapper}>
          {avatarUrl && <img className={styles.friend__image} src={avatarUrl} alt='img' height={40} width={40} />}
          <div className={`${styles.friend_info} flex-break-word`}>
            <Typography variant='body1' color='white'>{friend.username}</Typography>
            <div className={styles.friend_score__wrapper}>
              <TreeScoreIcon />
              <Typography variant='subheadline2' color='white'>{formattedAsset.amount}</Typography>
            </div>
          </div>
        </div>
        <IconButton disabled={isDisabled} onClick={handleRemind}>{rightIcon}</IconButton>
      </div>
    </div>
  );
}

export default FriendItem;
