import React from 'react';

interface IProps {
  variant?: 'title-large' | 'title1' | 'title2' | 'title3' | 'subheadline1' | 'subheadline2' | 'body1' | 'caption1' | 'caption2',
  color?: 'primary' | 'black' | 'white' | 'secondary' | 'error',
  style?: object,
  className?: string,
  children: React.ReactNode,
}

const Typography = ({ variant, color, children, style, className }: IProps) => {
  return (
    <div style={style} className={`${variant || 'title1'} color--${color || 'black'} ${className ? className : ''}`}>
      {children}
    </div>
  );
}

export default Typography;
