import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: { notified: false },
  reducers: {
    notify: (state) => {
      state.notified = true;
    },
    resetNotification: (state) => {
      state.notified = false;
    },
  },
});

export const { notify, resetNotification } = notificationSlice.actions;
export default notificationSlice.reducer;