import { useTonAddress, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useTranslation } from 'react-i18next';
import Typography from '../../common/typography';

import { ReactComponent as DisconnectIcon } from '../../../assets/wallet/DisconnectIcon.svg';
import { ReactComponent as ConnectIcon } from '../../../assets/wallet/ConnectIcon.svg';

import styles from './TonWallet.module.scss';
import IconButton from '../../common/iconButton';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DialogDisconnectTonWallet from '../dialogDisconnectTonWallet';
import { handleErrors } from '../../../core/helpers';
import { useAppSelector } from '../../../store/hooks';
import { toast } from 'react-toastify';

const TonWallet = () => {
  const wallet = useTonWallet();
  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const tasks = useAppSelector(state => state.settings.settings.tasks);
  const userTasks = useAppSelector(state => state.account.userTasks);
  const connectTonWalletTask = useMemo(() => tasks.find((item) => item.settings.taskType === 'ConnectTonWallet'), [tasks]);
  const isTaskCompleted = useMemo(() => {
    const task = userTasks.find((item) => item.taskId === connectTonWalletTask?.id);
    return task?.status === 'RewardClaimed';
  }, [userTasks, connectTonWalletTask]);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await tonConnectUI.disconnect();
      toast(t('toasts.walletDisconnectedText', { walletName: 'TON' }));
      setIsOpen(false);
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setIsOpen, tonConnectUI, t]);

  const handleOpen = useCallback(async () => {
    try {
      tonConnectUI.openModal();
    } catch (err: any) {
      handleErrors(err);
    }
  }, [tonConnectUI]);

  useEffect(() => {
    tonConnectUI.onStatusChange(async (wallet: any) => {
      if (wallet) {
        try {
          toast(t('toasts.walletConnectedText', { walletName: 'TON' }));
        } catch (err: any) {
          handleErrors(err);
        }
      }
    });
  }, [tonConnectUI, t]);

  return (
    <div className={styles.wallet_address__container}>
      {isOpen && <DialogDisconnectTonWallet onClick={handleClick} onClose={() => setIsOpen(false)} isLoading={isLoading} />}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', overflow: 'hidden', width: '100%' }}>
        <Typography variant='caption1' color='secondary'>{t('pages.wallet.sections.wallet.walletTitle', { walletName: 'TON' })}</Typography>
        {wallet && address && <Typography variant='subheadline2' color='white' className='break-word-ellipsis'>{address}</Typography>}
        {!wallet && !isTaskCompleted && <Typography variant='subheadline2' color='white' style={{ width: '100%' }}>{t('pages.wallet.sections.wallet.tonWalletNotConnectedText')}</Typography>}
        {!wallet && isTaskCompleted && <Typography variant='subheadline2' color='white'>{t('pages.wallet.sections.wallet.tonWalletNotConnectedText2')}</Typography>}
      </div>
      {wallet && <div className={styles.wallet_address__buttons}>
        <IconButton onClick={() => setIsOpen(true)}><DisconnectIcon /></IconButton>
      </div>}
      {!wallet && isTaskCompleted && <div className={styles.wallet_address__buttons}>
        <IconButton onClick={handleOpen}><ConnectIcon /></IconButton>
      </div>}
    </div>
  );
}

export default TonWallet;
