import React from 'react';

import styles from './TreeLoader.module.scss';
import Typography from '../../common/typography';
import AnimatedGif from '../../common/animatedGif';

// import WateringCan from '../../../assets/WateringCan.gif';
import WateringCan from '../../../assets/themeIcons/newYear/loaders/NewYearWateringCan.gif';
import { useTranslation } from 'react-i18next';

const TreeLoader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <AnimatedGif src={WateringCan} alt='gif' style={{ minWidth: '150px', minHeight: '150px' }} />
      <Typography variant='subheadline1' color='primary'>{t('loaders.tree.title')}</Typography>
      <Typography variant='title2' color='white'>{t('loaders.tree.secondTitle')}</Typography>
    </div>
  );
}

export default TreeLoader;
