import { configureStore } from '@reduxjs/toolkit'
import accountSlice from './reducers/accountSlice';
import settingsSlice from './reducers/settingsSlice';
import statisticsSlice from './reducers/statisticsSlice';
import translationsSlice from './reducers/translationsSlice';
import walletSlice from './reducers/walletSlice';
import notificationSlice from './reducers/notificationSlice';

export const store = configureStore({
  reducer: {
    settings: settingsSlice,
    account: accountSlice,
    wallet: walletSlice,
    statistics: statisticsSlice,
    translations: translationsSlice,
    notification: notificationSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;