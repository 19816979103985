import React from 'react';
import AnimatedGif from '../../animatedGif';

// import styles from './MainLoader.module.scss';
// import { ReactComponent as Loader } from '../../../../assets/loaders/MainLoader.svg';
import Loader from '../../../../assets/themeIcons/newYear/loaders/NewYearMainLoaderIcon.gif';

const MainLoader = ({ size = 85 }: { size?: number }) => { // default size 85
  return (
    // <div className={styles.loader__wrapper} style={{ width: size, height: size }}>
    //   <Loader className='importantSvg' style={{ width: size, height: size }} />
    // </div>
    <div style={{ width: size, height: size }}>
      <AnimatedGif src={Loader} alt='loader' />
    </div>
  );
}

export default MainLoader;
