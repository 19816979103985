import { Fragment, useCallback, useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleErrors } from '../../../core/helpers';
import storage from '../../../core/storage';
import { SceneQuality, ISceneSettingsItem } from '../../../types/types';
import Button from '../../common/button';
import Dialog from '../../common/dialog';
import Divider from '../../common/divider';
import ButtonLoader from '../../common/loaders/buttonLoader';
import Typography from '../../common/typography';
import SceneSettingsItem from '../sceneSettingsItem';
import SceneSettingsList from '../sceneSettingsList';

import styles from './DialogChangeScene.module.scss';

interface IProps {
  type: SceneQuality,
  handleClose: () => void,
}

const DialogChangeScene = ({ type, handleClose }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeType, setActiveType] = useState<SceneQuality>(type);
  const { t } = useTranslation();

  const settings = useMemo(() =>
    [
      {
        title: t('pages.tree.popups.settings.modes.solo'),
        type: SceneQuality.Solo,
      },
      {
        title: t('pages.tree.popups.settings.modes.low'),
        type: SceneQuality.Low,
      },
      {
        title: t('pages.tree.popups.settings.modes.medium'),
        type: SceneQuality.Medium,
      },
      {
        title: t('pages.tree.popups.settings.modes.high'),
        type: SceneQuality.High,
      },
      // {
      //   title: t('pages.tree.popups.settings.modes.winter'),
      //   type: SceneQuality.Winter,
      // },
      // {
      //   title: t('pages.tree.popups.settings.modes.thunder'),
      //   type: SceneQuality.Thunder,
      // }
    ], [t]);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await storage.setItem(process.env.REACT_APP_CLOUD_SCENE_TYPE_KEY as string, activeType);
      window.location.reload();
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [activeType]);

  return (
    <Dialog
      onClose={handleClose}
      title={t('pages.tree.popups.settings.title')}
    >
      <div className={styles.dialog__content}>
        <SceneSettingsList
          items={settings}
          renderItem={(item: ISceneSettingsItem, index: number) =>
            <Fragment key={item.type}>
              <SceneSettingsItem isActive={activeType === item.type} title={item.title} onClick={() => setActiveType(item.type)} />
              {index !== settings.length - 1 && <Divider />}
            </Fragment>}
        />
        <Typography variant='subheadline2' color='secondary'>{t('pages.tree.popups.settings.description')}</Typography>
        <Button
          onClick={handleClick}
          disabled={isLoading}
          endIcon={isLoading ? <ButtonLoader /> : null}
        >
          <Typography color='primary' variant='subheadline1'>{t('pages.tree.popups.settings.buttonText')}</Typography>
        </Button>
      </div>
    </Dialog>
  );
}

export default DialogChangeScene;
