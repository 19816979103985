import * as THREE from 'three';

const cdnUrl = process.env.REACT_APP_CDN_URL;
const minDistance = 50;
const maxDistance = 100;

const cloudParticles1 = [];
const cloudParticles2 = [];
const cloudParticles3 = [];
const flash = new THREE.PointLight(0x586AAC, 0, 0, 2.5);

export class Thunder extends THREE.Mesh {
  constructor() {
    super();

    flash.position.set(10, 10, 10);
    this.add(flash);

    const loader = new THREE.TextureLoader();

    const texture1 = loader.load(`${cdnUrl}/assets/thunder/vecteezy_rain-clouds-and-black-sky-textured-background_10123744_360-min.jpg`);
    const texture2 = loader.load(`${cdnUrl}/assets/thunder/vecteezy_rain-clouds-and-black-sky-textured-background_10121810_444-min.jpg`);
    const texture3 = loader.load(`${cdnUrl}/assets/thunder/vecteezy_rain-clouds-and-black-sky-textured-background_10121519_408-min.jpg`);

    const cloudGeometry1 = new THREE.PlaneGeometry(2500, 2500);
    const cloudMaterial1 = new THREE.MeshLambertMaterial({
      map: texture1,
      transparent: false,
    });

    for (let p = 0; p < 25; p++) {
      const cloud1 = new THREE.Mesh(cloudGeometry1, cloudMaterial1);
      cloud1.position.set(
        0,
        80,
        0
      );
      cloud1.color = 0x0B1017;
      cloud1.rotation.x = Math.PI / 2;
      cloud1.rotation.y = 0;
      cloud1.rotation.z = 100;

      cloudParticles1.push(cloud1);

      this.add(cloud1);
    }

    const cloudGeometry2 = new THREE.PlaneGeometry(2500, 2500);
    const cloudMaterial2 = new THREE.MeshLambertMaterial({
      map: texture2,
      transparent: false
    });

    for (let p = 0; p < 25; p++) {
      const cloud2 = new THREE.Mesh(cloudGeometry2, cloudMaterial2);
      cloud2.position.set(
        0,
        79,
        0
      );
      cloud2.rotation.x = Math.PI / 2;
      cloud2.rotation.y = 0;
      cloud2.rotation.z = 100;

      cloudParticles2.push(cloud2);

      this.add(cloud2);
    }

    const cloudGeometry3 = new THREE.PlaneGeometry(2500, 2500);
    const cloudMaterial3 = new THREE.MeshLambertMaterial({
      map: texture3,
      transparent: false
    });

    for (let p = 0; p < 25; p++) {
      const cloud3 = new THREE.Mesh(cloudGeometry3, cloudMaterial3);
      cloud3.position.set(
        0,
        78,
        0
      );
      cloud3.rotation.x = Math.PI / 2;
      cloud3.rotation.y = 0;
      cloud3.rotation.z = 100;
      
      cloudParticles3.push(cloud3);

      this.add(cloud3);
    }
  }

  update() {
    cloudParticles1.forEach(p => {
      p.rotation.z -= 0.0006;
    });

    cloudParticles2.forEach(p => {
      p.rotation.z -= 0.0005;
    });

    cloudParticles3.forEach(p => {
      p.rotation.z -= 0.0004;
    });

    if (Math.random() > 0.93 || flash.power > 100000) {
      if (flash.power < 100000)
        flash.position.set(
          this.getRandomPosition().x,
          50 + Math.random() * 25,
          90
        );

      flash.power = 50000 + Math.random() * 500000;
    }
  }

  getRandomPosition() {
    const r = minDistance + Math.random() * maxDistance;
    const theta = 2 * Math.PI * Math.random();
    return new THREE.Vector3(r * Math.cos(theta), 0, r * Math.sin(theta));
  }
}