import { useTranslation } from 'react-i18next';
import Dialog from '../../common/dialog';

import styles from './DialogDisconnectTonWallet.module.scss';

// import disconnectImage from '../../../assets/wallet/BreakChain.png';
import disconnectImage from '../../../assets/wallet/BreakChainWinter.png';
import Typography from '../../common/typography';
import Button from '../../common/button';
import ButtonLoader from '../../common/loaders/buttonLoader';

interface IProps {
  onClose: () => void,
  onClick: () => void,
  isLoading: boolean,
}

const DialogDisconnectTonWallet = ({ onClose, onClick, isLoading }: IProps) => {
  const { t } = useTranslation();
  return (
    <Dialog title={t('pages.wallet.popups.disconnectTonWallet.header')} onClose={onClose}>
      <div className={styles.dialog__content}>
        <img src={disconnectImage} alt='img' height={277}></img>
        <div className={styles.info__wrapper}>
          <Typography variant="subheadline1" color="primary">
            {t('pages.wallet.popups.disconnectTonWallet.title')}
          </Typography>
          <Typography variant="title2" color="white" style={{ textAlign: 'center' }}>
            {t('pages.wallet.popups.disconnectTonWallet.secondTitle')}
          </Typography>
          <Typography variant="subheadline2" color="secondary" style={{ display: 'inline-block', wordBreak: 'break-word' }}>
            {t('pages.wallet.popups.disconnectTonWallet.description')}
          </Typography>
          <Button
            onClick={onClick}
            disabled={isLoading}
            endIcon={isLoading ? <ButtonLoader /> : null}
          >
            {t('pages.wallet.popups.disconnectTonWallet.buttonText')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default DialogDisconnectTonWallet;
