export interface IWallet {
  getAddress(): string;
  getMnemonicPhrase(): string;
  signTransaction(transaction: any): any;
  createTransaction(amount: number, address: string, networkVersion: string, fee?: number): {
    amount: number;
    addressFrom: string;
    addressTo: string;
    pubKey: string;
    networkVersion: string;
    randomSalt: string;
    type: string;
    fee: number;
    assetId: string;
    data: any;
    signature: string;
    hash: string;
  }
};

interface ITransactionData {
  type: string,
  transacrtionType: string,
}

export interface ITransaction {
  networkVersion: string,
  dateTime: string,
  amount: number,
  fee: number,
  addressFrom: string,
  addressTo: string,
  pubKey: string,
  signature: string,
  hash: string,
  randomSalt: string,
  status: string,
  type: string,
  number: number,
  blockNumber: number,
  assetId: string,
  data: ITransactionData,
}

export interface IAsset {
  assetId: number,
  type: AssetType,
  name: string,
  decimals: number,
}
export interface IClaimReferralAsset {
  amount: number,
  assetId: number,
  type: AssetType,
}

export interface ISendTransaction {
  networkVersion: string,
  amount: number,
  addressFrom: string,
  addressTo: string,
  pubKey: string,
  signature: string,
  hash: string,
  randomSalt: string,
}

export interface IAddressBalance {
  stable: number,
  pending: number,
  outgoing: number
}

export enum FindObjectTaskAssets {
  Candy = 'Candy',
  Flower = 'Flower',
  Snowman = 'Snowman',
  Present = 'Present',
};

export enum AssetType {
  Balance = 'Balance',
  Score = 'Score',
}

export interface IReplenish {
  repeat: number,
  assets: {
    assetId: number,
    amount: number,
  }[]
}

export interface INetworkSettings {
  networkVersion: string,
  decimals: number,
  minTransactionFee: number,
  payoutAddress?: string | null,
  hubUrl: string,
  explorerUrl: string,
}
export interface IGrow {
  maxGrowFactor: number,
}

export interface ISocialSettings {
  miniAppUrl: string;
  botUrl: string;
  communityUrl: string;
}

export interface IDayReward {
  number: number,
  assets: {
    assetId: null | number,
    amount: number
  }[]
}

export interface IApplicationSettings {
  replenish: IReplenish,
  wallet: {
    minimumReferrals: number,
  },
  networkSettings: INetworkSettings,
  social: ISocialSettings;
  tasks: ITask[],
  grow: IGrow,
  claimRewards: {
    repeat: number;
  },
  dailyRewardsSchedule: {
    days: IDayReward[]
  },
  ads: IAds;
  assets: IAsset[]
}

export interface IAccount {
  id: number,
  username: string,
  referralsCount: number,
  walletAddress: string,
  lastReplenish: string,
  replenishCount: number,
  lastClaimRewards: string,
  score: number,
  rewards: number,
  hasDailyReward: boolean,
  currentDailyRewardStreak: number,
  lastAdView: string,
  lastAdClaimRewards: string,
  treeSettings?: ITreeSettings
}

export interface IReferal {
  id: number,
  username: string,
  score: number,
  allowRemind: boolean,
  photoUrl?: string,
  treeSettings?: ITreeSettings
}

export interface IReferals {
  data: IReferal[],
  total: number,
}

type TaskSettings = ISubscribeTaskSettings | IWatchTaskSettings | IReferralTaskSettings | IProgressTaskSettings | IShareStorySettings | IChangeUsernameSettings | IFindObjectTaskSettings | IBoostChannelTaskSettings | IDonateTaskSettings | IConnectTonWalletTaskSettings | IAddHomeScreenShortcutTaskSettings | ISetEmojiStatusTaskSettings;

export interface ITask {
  id: number,
  title: string,
  titleTranslationKey: string,
  description: string,
  type: ITaskType,
  group: ITaskGroup,
  reward: 0,
  settings: TaskSettings,
  displayOrder: 0,
  expiresAt: string,
  isExpired: number,
  premiumOnly: boolean,
  reccuring: boolean,
  startsAt: string,
  repetitionTime: number,
}

export interface IWatchTask extends ITask {
  settings: IWatchTaskSettings,
}
export interface ISubscribeTask extends ITask {
  settings: ISubscribeTaskSettings,
}
export interface IReferralTask extends ITask {
  settings: IReferralTaskSettings,
}
export interface IProgressTask extends ITask {
  settings: IProgressTaskSettings,
}
export interface IFindObjectTask extends ITask {
  settings: IFindObjectTaskSettings,
}

export interface IPromoTask extends ITask {
  settings: IShareStorySettings | IChangeUsernameSettings;
}

export interface IShareStoryTask extends ITask {
  settings: IShareStorySettings;
}
export interface IChangeUsernameTask extends ITask {
  settings: IChangeUsernameSettings;
}

export interface IBoostChannelTask extends ITask {
  settings: IBoostChannelTaskSettings;
}
export interface IDonateTask extends ITask {
  settings: IDonateTaskSettings;
}

export interface IConnectTonWalletTask extends ITask {
  settings: IConnectTonWalletTaskSettings;
}
export interface IAddHomeScreenShortcutTask extends ITask {
  settings: IAddHomeScreenShortcutTaskSettings;
}
export interface ISetEmojiStatusTask extends ITask {
  settings: ISetEmojiStatusTaskSettings;
}

export interface IDonateTaskState {
  taskType: ITaskType,
  invoiceLink: string,
  Type: ITaskType,
}

export interface IConnectTonWalletTaskState {
  taskType: ITaskType,
  payload: string,
  Type: ITaskType,
}

export type ITaskType = 'Subscribe' | 'Watch' | 'Referral' | 'Progress' | 'ChangeUsername' | 'ShareStory' | 'FindObject' | 'BoostChannel' | 'Donate' | 'ConnectTonWallet' | 'AddHomeScreenShortcut' | 'SetEmojiStatus';
export type ITaskState = null | IDonateTaskState | IConnectTonWalletTaskState;
export type ITaskGroup = 'Season' | 'Subscribe' | 'Watch' | 'Referral' | 'Progress' | 'Promo' | 'Support';
export interface IUserTask {
  id: number,
  taskId: number,
  status: string,
  operation: string,
  updatedAt: string,
  state: ITaskState,
}

export interface IDonateUserTask extends IUserTask {
  state: IDonateTaskState
}
export interface IConnectTonWalletTask extends IUserTask {
  state: IConnectTonWalletTaskState
}

export interface IProcessTask {
  id: number,
  taskId: number,
  status: string,
  operation: string,
  updatedAt: string,
  state: ITaskState,
}

type PlatformType = "Telegram" | "X" | "Youtube" | "Instagram";
export interface ISubscribeTaskSettings {
  link: string,
  platform: PlatformType,
  taskType: "Subscribe",
}
export interface IFindObjectTaskSettings {
  taskType: "FindObject",
  data: {
    asset: FindObjectTaskAssets,
    count?: number,
  },
}
export interface IWatchTaskSettings {
  link: string,
  platform: PlatformType,
  taskType: "Watch",
  protected?: boolean,
}
export interface IReferralTaskSettings {
  count: number,
  taskType: "Referral",
}

export interface IProgressTaskSettings {
  value: number,
  measure: "Score" | "ReplenishCount",
  taskType: "Progress",
}

export interface IChangeUsernameSettings {
  substring: string,
  taskType: 'ChangeUsername',
}
export interface IBoostChannelTaskSettings {
  channelId: string,
  taskType: 'BoostChannel',
}

export interface IDonateTaskSettings {
  amount: number,
  currency: string,
  provider: 'Telegram',
  taskType: 'Donate',
}

export interface IConnectTonWalletTaskSettings {
  taskType: "ConnectTonWallet",
}

export interface IAddHomeScreenShortcutTaskSettings {
  taskType: "AddHomeScreenShortcut",
}
export interface ISetEmojiStatusTaskSettings {
  taskType: "SetEmojiStatus",
  emoji: string,
}

export interface IShareStorySettings {
  taskType: 'ShareStory',
}


export type IWatchTaskItem = IWatchTask & IUserTask;
export type ISubscribeTaskItem = ISubscribeTask & IUserTask;
export type IReferralTaskItem = IReferralTask & IUserTask;
export type IProgressTaskItem = IProgressTask & IUserTask;
export type IShareStoryTaskItem = IShareStoryTask & IUserTask;
export type IChangeUsernameTaskItem = IChangeUsernameTask & IUserTask;
export type IFindOjbectTaskItem = IFindObjectTask & IUserTask;
export type IBoostChannelTaskItem = IBoostChannelTask & IUserTask;
export type IDonateTaskItem = IDonateTask & IUserTask;
export type IConnectTonWalletTaskItem = IConnectTonWalletTask & IUserTask;
export type IAddHomeScreenShortcutTaskItem = IAddHomeScreenShortcutTask & IUserTask;
export type ISetEmojiStatusTaskItem = ISetEmojiStatusTask & IUserTask;
export type ITaskItem = ITask & IUserTask;
export type IUserTaskMap = Map<number, IUserTask>;


export interface IRank {
  id: number,
  username: string,
  value: number,
  rank: number,
  treeSettings?: ITreeSettings
  photoUrl?: string,
}

export interface IFetchedRanks {
  items: IRank[],
  ownRank: IRank,
}

export interface IStatistics {
  totalUsersCount: number,
  totalReferrals: number,
  totalReplenishCount: number,
  overallScore: number,
}

export enum RankType {
  Score = 'score',
  Referrals = 'referrals',
  ReplenishCount = 'replenishCount'
}

export enum SubscribeTaskPlatform {
  Telegram = 'Telegram',
  Twitter = 'X',
  Instagram = 'Instagram',
  Youtube = 'Youtube'
}

export enum PromoTaskType {
  ChangeUsername = 'ChangeUsername',
  ShareStory = 'ShareStory'
}

export interface IShowPromiseResult {
  done: boolean; // true if user watch till the end, otherwise false
  description: string; // event description
  state: 'load' | 'render' | 'playing' | 'destroy'; // banner state
  error: boolean; // true if event was emitted due to error, otherwise false
}

export interface IAds {
  repeat: number,
  blockId: number,
  debug: boolean,
  rewardAssets: {
    assetId: number,
    amount: number,
  }[]
}

export interface ITreeSettings {
  seed: number,
  type: string,
}

export interface ITreeItem {
  score: number,
  seed: number,
  type: TreeType,
  nickname?: string,
}

export interface ISceneSettingsItem {
  title: string,
  type: SceneQuality
}

export enum LinkType {
  Copy = 'copy',
  Share = 'share'
}

export interface ITranslation {
  key: string,
  value: string
}

export enum SceneQuality {
  Solo = 'solo',
  Winter = 'winter',
  Thunder = 'thunder',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Colorfull = 'colorfull',
  Default = 'default',
}

export enum TreeType {
  Ash = 'Ash',
  Aspen = 'Aspen',
  Oak = 'Oak',
  Pine = 'Pine'
}

export enum InitialGrowPercent {
  Ash = 15,
  Aspen = 15,
  Oak = 15,
  Pine = 25
}

export enum FpsRate {
  Lowest = 5000,
  Medium = 30 / 1000,
}

export interface IConfiguration {
  credentials: string,
  type: string,
  seed: string,
}