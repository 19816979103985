import * as THREE from 'three';
import { Ground } from './ground';
import { Grass } from '../grass';
import { Rocks } from '../rocks';
import { Thunder } from '../thunder';
import { SkyboxOptions, Skybox } from '../skybox';

export class Environment extends THREE.Object3D {
  constructor() {
    super();

    this.ground = new Ground();
    this.add(this.ground);

    this.grass = new Grass();
    this.add(this.grass);

    const skyboxOptions = new SkyboxOptions();
    skyboxOptions.castShadow = true;
    this.skybox = new Skybox(skyboxOptions);
    this.add(this.skybox);

    this.rocks = new Rocks();
    this.add(this.rocks);

    this.thunder = new Thunder();
    this.add(this.thunder);
  }

  update(elapsedTime) {
    this.grass.update(elapsedTime);
    this.thunder.update();
  }
}