import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/Addons.js';
import { simplex2d } from './noise';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

const cdnUrl = process.env.REACT_APP_CDN_URL;

export class WinterOptions {
  /**
   * Number of grass instances
   */
  grassInstanceCount = 1000;

  /**
   * Maximum number of grass instances
   */
  maxGrassInstanceCount = 2000;

  /**
   * Size of the grass patches
   */
  grassScale = 40;

  /**
   * Patchiness of the grass
   */
  grassPatchiness = 0.3;

  /**
   * Scale factor for the grass model
   */
  grassSize = { x: 3, y: 2, z: 3 };

  /**
   * Maximum variation in the grass size
   */
  grassSizeVariation = { x: 1, y: 2, z: 1 };

  snowParticleCount = 0;
  snowMaxRange = 800;
  snowMinRange = 400;

  receiveShadow = true;
  castShadow = true;
}

let loaded = false;
let _grassMesh = null;
// let _snowmanMesh = null;
let _igloosMesh = null;

export class Winter extends THREE.Object3D {
  constructor(options = new WinterOptions()) {
    super();

    /**
     * @type {WinterOptions}
     */
    this.options = options;

    this.flowers = new THREE.Group();
    this.add(this.flowers);

    this.fetchAssets().then(() => {
      this.generateGrass();
      // this.generateSnowmans(_snowmanMesh);
      this.generateIgloos(_igloosMesh);
    });
  }

  get instanceCount() {
    return this.grassMesh?.count ?? this.options.grassInstanceCount;
  }

  set instanceCount(value) {
    this.grassMesh.count = value;
  }

  /**
   *
   * @returns {Promise<THREE.Geometry>}
   */
  async fetchAssets() {
    if (loaded) return;

    const gltfLoader = new GLTFLoader();

    const [grass, igloos] = await Promise.all([
      gltfLoader.loadAsync(`${cdnUrl}/assets/grass.glb`),
      // gltfLoader.loadAsync(`${cdnUrl}/assets/winter/snowman.glb`),
      gltfLoader.loadAsync(`${cdnUrl}/assets/winter/igloo.glb`),
    ]);

    _grassMesh = grass.scene.children[0];
    // _snowmanMesh = snowman.scene.children[0];
    _igloosMesh = igloos.scene.children[0];


    loaded = true;
  }

  update(elapsedTime) {
    this.traverse((o) => {
      if (o.isMesh && o.material?.userData.shader) {
        o.material.userData.shader.uniforms.uTime.value = elapsedTime;
      }
    });
  }

  generateGrass() {
    const grassMaterial = new THREE.MeshPhongMaterial({
      map: _grassMesh.material.map,
      // Add some emission so grass has some color when not lit
      emissive: new THREE.Color(0x308040),
      emissiveIntensity: 0.05,
      transparent: false,
      alphaTest: 0.5,
      depthTest: true,
      depthWrite: true,
      side: THREE.DoubleSide,
    });

    // Decrease grass brightness
    grassMaterial.color.multiplyScalar(0.6);

    this.grassMesh = new THREE.InstancedMesh(
      _grassMesh.geometry,
      grassMaterial,
      this.options.maxGrassInstanceCount,
    );

    this.generateGrassInstances();

    this.add(this.grassMesh);
  }

  generateGrassInstances() {
    const dummy = new THREE.Object3D();

    let count = 0;
    for (let i = 0; i < this.options.maxGrassInstanceCount; i++) {
      const r = 10 + Math.random() * 500;
      const theta = Math.random() * 2.0 * Math.PI;

      // Set position randomly
      const p = new THREE.Vector3(r * Math.cos(theta), 0, r * Math.sin(theta));

      const n =
        0.5 +
        0.5 *
        simplex2d(
          new THREE.Vector2(
            p.x / this.options.grassScale,
            p.z / this.options.grassScale,
          ),
        );

      if (
        n > this.options.grassPatchiness &&
        Math.random() + 0.6 > this.options.grassPatchiness
      ) {
        continue;
      }

      dummy.position.copy(p);

      // Set rotation randomly
      dummy.rotation.set(0, 2 * Math.PI * Math.random(), 0);

      // Set scale randomly
      dummy.scale.set(
        this.options.grassSizeVariation.x * Math.random() + this.options.grassSize.x,
        this.options.grassSizeVariation.y * Math.random() + this.options.grassSize.y,
        this.options.grassSizeVariation.z * Math.random() + this.options.grassSize.z,
      );

      // Apply the transformation to the instance
      dummy.updateMatrix();

      const color = new THREE.Color(
        0.25 + Math.random() * 0.1,
        0.3 + Math.random() * 0.3,
        0.1,
      );

      this.grassMesh.setMatrixAt(count, dummy.matrix);
      this.grassMesh.setColorAt(count, color);
      count++;
    }

    // Set count to only show up to `instanceCount` instances
    this.grassMesh.count = this.options.grassInstanceCount;

    this.grassMesh.receiveShadow = this.options.receiveShadow;
    this.grassMesh.castShadow = this.options.castShadow;

    // Ensure the transformation is updated in the GPU
    this.grassMesh.instanceMatrix.needsUpdate = true;
    this.grassMesh.instanceColor.needsUpdate = true;
  }

  generateIgloos(igloosMesh) {
    for (let i = 0; i < 30; i++) {
      const r = 10 + Math.random() * 200;
      const theta = Math.random() * 2.0 * Math.PI;

      // Set position randomly
      const p = new THREE.Vector3(r * Math.cos(theta), 0, r * Math.sin(theta));

      const n =
        0.5 +
        0.5 *
        simplex2d(
          new THREE.Vector2(
            p.x / this.options.grassScale,
            p.z / this.options.grassScale,
          ),
        );

      if (
        n > this.options.grassPatchiness &&
        Math.random() + 0.8 > this.options.grassPatchiness
      ) {
        continue;
      }

      const flower = igloosMesh.clone();
      flower.position.copy(p);
      flower.rotation.set(0, 2 * Math.PI * Math.random(), 0);
      const scale = Math.random() * 0.4 + 0.3;
      flower.scale.set(scale, scale, scale);

      this.flowers.add(flower);
    }
  }
}


const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
gltfLoader.setDRACOLoader(dracoLoader);

function getClosestParent(item, parentName) {
  if (!item?.parent) {
    return null;
  }
  if (item.parent.name !== parentName) {
    return getClosestParent(item.parent, parentName);
  }

  return item;
}

function subscribeOnClick(params, objects, parent, name) {
  const { renderer, raycaster, camera, callback, mouse } = params;
  const treeContainer = document.getElementById('tree');
  treeContainer.addEventListener('click', onDocumentMouseDown, false);

  function onDocumentMouseDown(event) {
    event.preventDefault();
    mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(objects);
    if (intersects.length > 0) {
      callback(name, intersects.map(i => getClosestParent(i.object, parent)).filter(i => i));
    }
  }
}

export class Snowman extends THREE.Group {
  constructor(params) {
    super();
    Snowman.mesh = Snowman.mesh || {};
    this.params = params;
    this.load().then(() => {
      const snowman1 = generateInstances(Snowman.mesh.snowman, 10, {
        base: 1,
        min: 1,
        max: 1,
      }, { y: 1 }, 200);
      const snowmans = [...snowman1.children];
      snowman1.name = `snowman-wrap`;
      this.add(snowman1);
      subscribeOnClick(params, snowmans, `snowman-wrap`, 'snowman');
    });
  }

  async load() {
    if (this.loaded) {
      return;
    }
    const snowman = await gltfLoader.loadAsync(`${cdnUrl}/assets/winter/snowman.glb`);
    Snowman.mesh.snowman = snowman.scene;
    Snowman.loaded = true;
  }
}

export class Present extends THREE.Group {
  constructor(params) {
    super();
    Present.mesh = Present.mesh || {};
    this.params = params;
    this.load().then(() => {
      const presentGroup = generateInstances(Present.mesh.present, 1, {
        base: 50,
        min: 50,
        max: 50,
      }, { x: 1, y: 0, z: 10 }, 1);
      const present = [...presentGroup.children];
      presentGroup.name = `present-wrap`;
      this.add(presentGroup);
      subscribeOnClick(params, present, `present-wrap`, 'present');
    });
  }

  async load() {
    if (this.loaded) {
      return;
    }
    const present = await gltfLoader.loadAsync(`${cdnUrl}/assets/winter/present.glb`);
    Present.mesh.present = present.scene;
    Present.loaded = true;
  }
}

function generateInstances(mesh, totalCount, size, position, maxRadius) {
  const instancedMesh = new THREE.InstancedMesh(
    mesh.geometry,
    mesh.material,
    200,
  );

  let count = 0;
  for (let i = 0; i < totalCount; i++) {
    const dummy = mesh.clone();

    const r = 50 + Math.random() * (maxRadius || 500);
    const theta = Math.random() * 2.0 * Math.PI;

    // Set position randomly
    const p = new THREE.Vector3(position?.x || r * Math.cos(theta), position?.y || 0, position?.z || r * Math.sin(theta));
    dummy.position.copy(p);

    // Set rotation randomly
    dummy.rotation.set(0, 2 * Math.PI * Math.random(), 0);

    // Set scale randomly
    const q = Math.random();
    const s = Math.min(Math.max(size.base * q, size.min), size.max);
    dummy.scale.set(s, s, s);

    // Apply the transformation to the instance
    dummy.updateMatrix();

    instancedMesh.setMatrixAt(count, dummy.matrix);
    count++;

    instancedMesh.add(dummy);
  }
  instancedMesh.count = count;

  // Ensure the transformation is updated in the GPU
  //instancedMesh.instanceMatrix.needsUpdate = true;

  //instancedMesh.castShadow = this.options.castShadow;

  return instancedMesh;
}

